var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ShoppingGuide_wrapper" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "导购员列表", name: "1" } },
            [
              _c(
                "div",
                { staticClass: "select_wrap" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "mr10",
                      attrs: { placeholder: "角色类型" },
                      model: {
                        value: _vm.QueryKpiUserInfo.type,
                        callback: function($$v) {
                          _vm.$set(_vm.QueryKpiUserInfo, "type", $$v)
                        },
                        expression: "QueryKpiUserInfo.type"
                      }
                    },
                    _vm._l(_vm.typeOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "充值状态" },
                      model: {
                        value: _vm.QueryKpiUserInfo.recharged,
                        callback: function($$v) {
                          _vm.$set(_vm.QueryKpiUserInfo, "recharged", $$v)
                        },
                        expression: "QueryKpiUserInfo.recharged"
                      }
                    },
                    _vm._l(_vm.rechargedOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  ),
                  _c("el-input", {
                    staticClass: "ml10",
                    staticStyle: { width: "200px" },
                    attrs: { clearable: true, placeholder: "请输入手机号搜索" },
                    model: {
                      value: _vm.QueryKpiUserInfo.mobile,
                      callback: function($$v) {
                        _vm.$set(_vm.QueryKpiUserInfo, "mobile", $$v)
                      },
                      expression: "QueryKpiUserInfo.mobile"
                    }
                  }),
                  _c("el-input", {
                    staticClass: "ml10",
                    staticStyle: { width: "200px" },
                    attrs: { clearable: true, placeholder: "请输入姓名搜索" },
                    model: {
                      value: _vm.QueryKpiUserInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.QueryKpiUserInfo, "name", $$v)
                      },
                      expression: "QueryKpiUserInfo.name"
                    }
                  }),
                  _c("el-input", {
                    staticClass: "ml10",
                    staticStyle: { width: "200px" },
                    attrs: { clearable: true, placeholder: "请输入用户ID" },
                    model: {
                      value: _vm.QueryKpiUserInfo.userId,
                      callback: function($$v) {
                        _vm.$set(_vm.QueryKpiUserInfo, "userId", $$v)
                      },
                      expression: "QueryKpiUserInfo.userId"
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.searchPage }
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { type: "primary", icon: "el-icon-download" },
                      on: { click: _vm.exportData }
                    },
                    [_vm._v("导出表格")]
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticClass: "secondsKill_table",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.QueryKpiUserList, border: "", stripe: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "userId", label: "用户ID" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "姓名" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "mobile", label: "手机号码", width: "130" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "brandName", label: "所属品牌" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "shopName", label: "所属门店" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "type", label: "角色类型", width: "110" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.type == "act"
                              ? _c("span", [_vm._v("活动负责人")])
                              : _vm._e(),
                            scope.row.type == "brand"
                              ? _c("span", [_vm._v("品牌负责人")])
                              : _vm._e(),
                            scope.row.type == "staff"
                              ? _c("span", [_vm._v("导购员")])
                              : _vm._e(),
                            scope.row.type == "finance"
                              ? _c("span", [_vm._v("财务负责人")])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "注册时间",
                      width: "180"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "recharged", label: "充值状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.recharged
                              ? _c("span", [_vm._v("已充值")])
                              : _vm._e(),
                            !scope.row.recharged
                              ? _c("span", [_vm._v("未充值")])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "totalBean", label: "总奖励豆" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.totalBean
                              ? _c("span", [
                                  _vm._v(_vm._s(scope.row.totalBean))
                                ])
                              : _c("span", [_vm._v("0")])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "totalScore", label: "总积分" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.totalScore
                              ? _c("span", [
                                  _vm._v(_vm._s(scope.row.totalScore))
                                ])
                              : _c("span", [_vm._v("0")])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "bonus", label: "总奖励金" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.bonus
                              ? _c("span", [_vm._v(_vm._s(scope.row.bonus))])
                              : _c("span", [_vm._v("0")])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "totalMoney", label: "总收入" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.totalMoney
                              ? _c("span", [
                                  _vm._v(_vm._s(scope.row.totalMoney))
                                ])
                              : _c("span", [_vm._v("0")])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "withdrawnMoney", label: "已提现金额" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.withdrawnMoney
                              ? _c("span", [
                                  _vm._v(_vm._s(scope.row.withdrawnMoney))
                                ])
                              : _c("span", [_vm._v("0")])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "availableMoney", label: "剩余账户金额" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.availableMoney
                              ? _c("span", [
                                  _vm._v(_vm._s(scope.row.availableMoney))
                                ])
                              : _c("span", [_vm._v("0")])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", fixed: "right", width: "200px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-tag",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.toDetail(scope.row)
                                  }
                                }
                              },
                              [_vm._v("详情")]
                            ),
                            _vm.isSuperAdmin
                              ? _c(
                                  "el-tag",
                                  {
                                    attrs: { type: "success" },
                                    on: {
                                      click: function($event) {
                                        return _vm.toeditDetail(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("编辑")]
                                )
                              : _vm._e(),
                            _vm.isSuperAdmin
                              ? _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title: "删除后将不可恢复，确定删除吗？"
                                    },
                                    on: {
                                      onConfirm: function($event) {
                                        return _vm.delConfirm(scope.row)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "el-tag",
                                      {
                                        attrs: {
                                          slot: "reference",
                                          type: "danger"
                                        },
                                        slot: "reference"
                                      },
                                      [_vm._v("删除")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tab-page" },
                [
                  _c("div"),
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.QueryKpiUserInfo.page,
                      "page-size": 10,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.QueryKpiUserTotal
                    },
                    on: { "current-change": _vm.handleCurrentChange }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.isSuperAdmin
            ? _c(
                "el-tab-pane",
                { attrs: { label: "审核列表", name: "2" } },
                [
                  _c(
                    "div",
                    { staticClass: "select_wrap" },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期"
                        },
                        model: {
                          value: _vm.AuditInfo.timeRange,
                          callback: function($$v) {
                            _vm.$set(_vm.AuditInfo, "timeRange", $$v)
                          },
                          expression: "AuditInfo.timeRange"
                        }
                      }),
                      _c("el-input", {
                        staticClass: "ml10",
                        staticStyle: { width: "200px" },
                        attrs: {
                          clearable: true,
                          placeholder: "请输入手机号搜索"
                        },
                        model: {
                          value: _vm.AuditInfo.mobile,
                          callback: function($$v) {
                            _vm.$set(_vm.AuditInfo, "mobile", $$v)
                          },
                          expression: "AuditInfo.mobile"
                        }
                      }),
                      _c("el-input", {
                        staticClass: "ml10 mr10",
                        staticStyle: { width: "200px" },
                        attrs: {
                          clearable: true,
                          placeholder: "请输入姓名搜索"
                        },
                        model: {
                          value: _vm.AuditInfo.name,
                          callback: function($$v) {
                            _vm.$set(_vm.AuditInfo, "name", $$v)
                          },
                          expression: "AuditInfo.name"
                        }
                      }),
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "审核状态" },
                          model: {
                            value: _vm.AuditInfo.status,
                            callback: function($$v) {
                              _vm.$set(_vm.AuditInfo, "status", $$v)
                            },
                            expression: "AuditInfo.status"
                          }
                        },
                        _vm._l(_vm.auditOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "ml10",
                          attrs: { type: "primary", icon: "el-icon-search" },
                          on: { click: _vm.searchPage2 }
                        },
                        [_vm._v("搜索")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      staticClass: "secondsKill_table",
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.AuditList, border: "", stripe: "" },
                      on: { "selection-change": _vm.handleSelectionChange }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "60", fixed: "left" }
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "userId", label: "用户ID" }
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "name", label: "姓名" }
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "mobile", label: "手机号码" }
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "type", label: "申请角色类型" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.type == "act"
                                    ? _c("span", [_vm._v("活动负责人")])
                                    : _vm._e(),
                                  scope.row.type == "brand"
                                    ? _c("span", [_vm._v("品牌负责人")])
                                    : _vm._e(),
                                  scope.row.type == "staff"
                                    ? _c("span", [_vm._v("导购员")])
                                    : _vm._e(),
                                  scope.row.type == "finance"
                                    ? _c("span", [_vm._v("财务负责人")])
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          5347621
                        )
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "createTime", label: "注册时间" }
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "legionName", label: "所属军团" }
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "brandName", label: "所属品牌" }
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "shopName", label: "所属门店" }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          fixed: "right",
                          width: "200px"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.status == "under_review"
                                    ? _c(
                                        "el-tag",
                                        {
                                          attrs: { slot: "reference" },
                                          on: {
                                            click: function($event) {
                                              return _vm.ConfirmSetAuditStatus(
                                                scope.row.id,
                                                "pass",
                                                scope.row.type
                                              )
                                            }
                                          },
                                          slot: "reference"
                                        },
                                        [_vm._v("同意")]
                                      )
                                    : _vm._e(),
                                  scope.row.status == "under_review"
                                    ? _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            slot: "reference",
                                            type: "danger"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.ConfirmSetAuditStatus(
                                                scope.row.id,
                                                "no_pass",
                                                scope.row.type
                                              )
                                            }
                                          },
                                          slot: "reference"
                                        },
                                        [_vm._v("拒绝")]
                                      )
                                    : _vm._e(),
                                  scope.row.status == "pass"
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "success" } },
                                        [_vm._v("已通过")]
                                      )
                                    : _vm._e(),
                                  scope.row.status == "no_pass"
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "danger" } },
                                        [_vm._v("已拒绝")]
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3560120871
                        )
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "tab-page" },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.SetIsAllow("pass")
                                }
                              }
                            },
                            [_vm._v("批量通过")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.SetIsAllow("no_pass")
                                }
                              }
                            },
                            [_vm._v("批量拒绝")]
                          )
                        ],
                        1
                      ),
                      _c("el-pagination", {
                        attrs: {
                          background: "",
                          "current-page": _vm.AuditInfo.page,
                          "page-size": 10,
                          layout: "total,prev, pager, next, jumper",
                          total: _vm.AuditTotal
                        },
                        on: { "current-change": _vm.handleCurrentChange2 }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "detailsGuide_drawer",
          attrs: {
            visible: _vm.detailsGuideDrawer,
            center: "",
            width: "1600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.detailsGuideDrawer = $event
            }
          }
        },
        [
          _c("p", { staticClass: "view_title" }, [_vm._v("基础信息")]),
          _c("el-card", { staticClass: "box-card" }, [
            _c("div", { staticClass: "basic_info_wrap" }, [
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("用户ID:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.userId))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("姓名:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.name))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("手机号码:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.mobile))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("所属品牌:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.brandName))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("所属门店:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.shopName))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("角色类型:")]),
                _vm.showDetail.type == "act"
                  ? _c("p", { staticClass: "data" }, [_vm._v("活动负责人")])
                  : _vm._e(),
                _vm.showDetail.type == "brand"
                  ? _c("p", { staticClass: "data" }, [_vm._v("品牌负责人")])
                  : _vm._e(),
                _vm.showDetail.type == "staff"
                  ? _c("p", { staticClass: "data" }, [_vm._v("导购员")])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("注册时间:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.createTime))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("充值状态:")]),
                _vm.showDetail.recharged
                  ? _c("p", { staticClass: "data" }, [_vm._v("已充值")])
                  : _vm._e(),
                !_vm.showDetail.recharged
                  ? _c("p", { staticClass: "data" }, [_vm._v("未充值")])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("总奖励豆:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.totalBean))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("总积分:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.totalScore))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("总奖励金:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.bonus))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("总收入:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.totalMoney))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("已提现金额:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.withdrawnMoney))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("剩余账户金额:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.availableMoney))
                ])
              ])
            ])
          ]),
          _c("p", { staticClass: "view_title" }, [_vm._v("客户信息")]),
          _c("el-card", { staticClass: "box-card" }, [
            _c("div", { staticClass: "basic_info_wrap" }, [
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("浏览客户:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.viewCount))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("裂变客户:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.fissionCount))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("报名客户:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.enrollmentCount))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("售券客户:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.cardCount))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("秒杀客户:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.seckillUserCount))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("逛店客户:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.goShopCount))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("过程转单客户:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.processSelfOrderCountOrder))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("进入直播间客户:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.liveViewCount))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("直播下订客户:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.liveOrderCusCount))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("活动签到客户:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.actSignCount))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("直播转单客户:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.liveSelfOrderChangeCount))
                ])
              ])
            ])
          ]),
          _c("p", { staticClass: "view_title" }, [_vm._v("订单信息")]),
          _c("el-card", { staticClass: "box-card" }, [
            _c("div", { staticClass: "basic_info_wrap" }, [
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("秒杀订单:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.seckillUserCountOrder))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("直播自签订单:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.liveSelfOrderCountOrder))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("直播贡献订单:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.liveOtherOrderCountOrder))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("直播自签转单:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.liveSelfOrderChangeCountOrder))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("直播贡献转单:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.liveOtherOrderChangeCountOrder))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("过程自签单:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.processSelfOrderCountOrder))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("过程贡献单:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.showDetail.processDevoteOrderCountOrder))
                ])
              ])
            ])
          ])
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "信息编辑",
            visible: _vm.editGuideDrawer,
            direction: "rtl",
            size: "500px",
            "custom-class": "editGuide_drawer"
          },
          on: {
            "update:visible": function($event) {
              _vm.editGuideDrawer = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.editGuideForm,
                rules: _vm.editGuideRules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入商品名称",
                      maxlength: "20",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.editGuideForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.editGuideForm, "name", $$v)
                      },
                      expression: "editGuideForm.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号码", prop: "mobile" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入手机号码",
                      maxlength: "20",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.editGuideForm.mobile,
                      callback: function($$v) {
                        _vm.$set(_vm.editGuideForm, "mobile", $$v)
                      },
                      expression: "editGuideForm.mobile"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "品牌商",
                    required: _vm.editGuideForm.type != "act"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.getShop },
                      model: {
                        value: _vm.editGuideForm.brandId,
                        callback: function($$v) {
                          _vm.$set(_vm.editGuideForm, "brandId", $$v)
                        },
                        expression: "editGuideForm.brandId"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "暂无", value: 0, disabled: "" }
                      }),
                      _vm._l(_vm.BrandList, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "门店",
                    required: _vm.editGuideForm.type != "act"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.editGuideForm.shopId,
                        callback: function($$v) {
                          _vm.$set(_vm.editGuideForm, "shopId", $$v)
                        },
                        expression: "editGuideForm.shopId"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "暂无", value: 0, disabled: "" }
                      }),
                      _vm._l(_vm.ShopList, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "角色类型", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.editGuideForm.type,
                        callback: function($$v) {
                          _vm.$set(_vm.editGuideForm, "type", $$v)
                        },
                        expression: "editGuideForm.type"
                      }
                    },
                    _vm._l(_vm.typeOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "注册时间", prop: "createTime" } },
                [_vm._v(_vm._s(_vm.editGuideForm.createTime))]
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitInfo }
                    },
                    [_vm._v("保 存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }