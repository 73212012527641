<template>
  <div class="ShoppingGuide_wrapper">
    <el-tabs type="card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="导购员列表" name="1">
        <div class="select_wrap">
          <el-select v-model="QueryKpiUserInfo.type" placeholder="角色类型" class="mr10">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select v-model="QueryKpiUserInfo.recharged" placeholder="充值状态">
            <el-option
              v-for="item in rechargedOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input
            class="ml10"
            :clearable="true"
            placeholder="请输入手机号搜索"
            v-model="QueryKpiUserInfo.mobile"
            style="width:200px"
          ></el-input>
          <el-input
            class="ml10"
            :clearable="true"
            placeholder="请输入姓名搜索"
            v-model="QueryKpiUserInfo.name"
            style="width:200px"
          ></el-input>
          <el-input
            class="ml10"
            :clearable="true"
            placeholder="请输入用户ID"
            v-model="QueryKpiUserInfo.userId"
            style="width:200px"
          ></el-input>
          <el-button
            type="primary"
            class="ml10"
            icon="el-icon-search"
            @click="searchPage"
          >搜索</el-button>
          <el-button type="primary" icon="el-icon-download" class="ml10" @click="exportData">导出表格</el-button>
        </div>
        <el-table
          class="secondsKill_table"
          :data="QueryKpiUserList"
          border
          stripe
          style="width: 100%"
        >
          <el-table-column prop="userId" label="用户ID"></el-table-column>
          <el-table-column prop="name" label="姓名"></el-table-column>
          <el-table-column prop="mobile" label="手机号码" width="130"></el-table-column>
          <el-table-column prop="brandName" label="所属品牌"></el-table-column>
          <el-table-column prop="shopName" label="所属门店"></el-table-column>
          <el-table-column prop="type" label="角色类型" width="110">
            <template slot-scope="scope">
              <span v-if="scope.row.type=='act'">活动负责人</span>
              <span v-if="scope.row.type=='brand'">品牌负责人</span>
              <span v-if="scope.row.type=='staff'">导购员</span>
              <span v-if="scope.row.type=='finance'">财务负责人</span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="注册时间" width="180"></el-table-column>
          <el-table-column prop="recharged" label="充值状态">
            <template slot-scope="scope">
              <span v-if="scope.row.recharged">已充值</span>
              <span v-if="!scope.row.recharged">未充值</span>
            </template>
          </el-table-column>
          <el-table-column prop="totalBean" label="总奖励豆">
            <template slot-scope="scope">
              <span v-if="scope.row.totalBean">{{scope.row.totalBean}}</span>
              <span v-else>0</span>
            </template>
          </el-table-column>
          <el-table-column prop="totalScore" label="总积分">
            <template slot-scope="scope">
              <span v-if="scope.row.totalScore">{{scope.row.totalScore}}</span>
              <span v-else>0</span>
            </template>
          </el-table-column>
          <el-table-column prop="bonus" label="总奖励金">
            <template slot-scope="scope">
              <span v-if="scope.row.bonus">{{scope.row.bonus}}</span>
              <span v-else>0</span>
            </template>
          </el-table-column>
          <el-table-column prop="totalMoney" label="总收入">
            <template slot-scope="scope">
              <span v-if="scope.row.totalMoney">{{scope.row.totalMoney}}</span>
              <span v-else>0</span>
            </template>
          </el-table-column>
          <el-table-column prop="withdrawnMoney" label="已提现金额">
            <template slot-scope="scope">
              <span v-if="scope.row.withdrawnMoney">{{scope.row.withdrawnMoney}}</span>
              <span v-else>0</span>
            </template>
          </el-table-column>
          <el-table-column prop="availableMoney" label="剩余账户金额">
            <template slot-scope="scope">
              <span v-if="scope.row.availableMoney">{{scope.row.availableMoney}}</span>
              <span v-else>0</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="200px">
            <template slot-scope="scope">
              <el-tag @click="toDetail(scope.row)">详情</el-tag>
              <el-tag type="success" @click="toeditDetail(scope.row)" v-if="isSuperAdmin">编辑</el-tag>
              <el-popconfirm title="删除后将不可恢复，确定删除吗？" @onConfirm="delConfirm(scope.row)" v-if="isSuperAdmin">
                <el-tag type="danger" slot="reference">删除</el-tag>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <div class="tab-page">
          <div></div>
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="QueryKpiUserInfo.page"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="QueryKpiUserTotal"
          ></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="审核列表" name="2" v-if="isSuperAdmin">
        <div class="select_wrap">
          <el-date-picker
            v-model="AuditInfo.timeRange"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
          <el-input
            class="ml10"
            :clearable="true"
            placeholder="请输入手机号搜索"
            v-model="AuditInfo.mobile"
            style="width:200px"
          ></el-input>
          <el-input
            class="ml10 mr10"
            :clearable="true"
            placeholder="请输入姓名搜索"
            v-model="AuditInfo.name"
            style="width:200px"
          ></el-input>
          <el-select v-model="AuditInfo.status" placeholder="审核状态">
            <el-option
              v-for="item in auditOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-button type="primary" class="ml10" icon="el-icon-search" @click="searchPage2">搜索</el-button>
        </div>
        <el-table
          class="secondsKill_table"
          :data="AuditList"
          border
          stripe
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="60" fixed="left"></el-table-column>
          <el-table-column prop="userId" label="用户ID"></el-table-column>
          <el-table-column prop="name" label="姓名"></el-table-column>
          <el-table-column prop="mobile" label="手机号码"></el-table-column>
          <el-table-column prop="type" label="申请角色类型">
            <template slot-scope="scope">
              <span v-if="scope.row.type=='act'">活动负责人</span>
              <span v-if="scope.row.type=='brand'">品牌负责人</span>
              <span v-if="scope.row.type=='staff'">导购员</span>
              <span v-if="scope.row.type=='finance'">财务负责人</span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="注册时间"></el-table-column>
          <el-table-column prop="legionName" label="所属军团"></el-table-column>
          <el-table-column prop="brandName" label="所属品牌"></el-table-column>
          <el-table-column prop="shopName" label="所属门店"></el-table-column>
          <el-table-column label="操作" fixed="right" width="200px">
            <template slot-scope="scope">
              <el-tag
                slot="reference"
                v-if="scope.row.status=='under_review'"
                @click="ConfirmSetAuditStatus(scope.row.id,'pass',scope.row.type)"
              >同意</el-tag>
              <el-tag
                type="danger"
                slot="reference"
                v-if="scope.row.status=='under_review'"
                @click="ConfirmSetAuditStatus(scope.row.id,'no_pass',scope.row.type)"
              >拒绝</el-tag>
              <el-tag type="success" v-if="scope.row.status=='pass'">已通过</el-tag>
              <el-tag type="danger" v-if="scope.row.status=='no_pass'">已拒绝</el-tag>
              <!-- <el-tag type="danger" @click="ConfirmSetAuditStatus(scope.row.id,'under_review')">给我变！</el-tag> -->
            </template>
          </el-table-column>
        </el-table>
        <div class="tab-page">
          <div>
            <el-button type="primary" @click="SetIsAllow('pass')">批量通过</el-button>
            <el-button type="primary" @click="SetIsAllow('no_pass')">批量拒绝</el-button>
          </div>
          <el-pagination
            background
            @current-change="handleCurrentChange2"
            :current-page="AuditInfo.page"
            :page-size="10"
            layout="total,prev, pager, next, jumper"
            :total="AuditTotal"
          ></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 导购员列表 - 查看详情 -->
    <el-dialog :visible.sync="detailsGuideDrawer" center width="1600px" class="detailsGuide_drawer">
      <p class="view_title">基础信息</p>
      <el-card class="box-card">
        <div class="basic_info_wrap">
          <div class="basic_info_item">
            <p class="name">用户ID:</p>
            <p class="data">{{showDetail.userId}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">姓名:</p>
            <p class="data">{{showDetail.name}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">手机号码:</p>
            <p class="data">{{showDetail.mobile}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">所属品牌:</p>
            <p class="data">{{showDetail.brandName}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">所属门店:</p>
            <p class="data">{{showDetail.shopName}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">角色类型:</p>
            <p class="data" v-if="showDetail.type=='act'">活动负责人</p>
            <p class="data" v-if="showDetail.type=='brand'">品牌负责人</p>
            <p class="data" v-if="showDetail.type=='staff'">导购员</p>
          </div>
          <div class="basic_info_item">
            <p class="name">注册时间:</p>
            <p class="data">{{showDetail.createTime}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">充值状态:</p>
            <p class="data" v-if="showDetail.recharged">已充值</p>
            <p class="data" v-if="!showDetail.recharged">未充值</p>
          </div>
          <div class="basic_info_item">
            <p class="name">总奖励豆:</p>
            <p class="data">{{showDetail.totalBean}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">总积分:</p>
            <p class="data">{{showDetail.totalScore}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">总奖励金:</p>
            <p class="data">{{showDetail.bonus}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">总收入:</p>
            <p class="data">{{showDetail.totalMoney}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">已提现金额:</p>
            <p class="data">{{showDetail.withdrawnMoney}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">剩余账户金额:</p>
            <p class="data">{{showDetail.availableMoney}}</p>
          </div>
        </div>
      </el-card>
      <p class="view_title">客户信息</p>
      <el-card class="box-card">
        <div class="basic_info_wrap">
          <div class="basic_info_item">
            <p class="name">浏览客户:</p>
            <p class="data">{{showDetail.viewCount}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">裂变客户:</p>
            <p class="data">{{showDetail.fissionCount}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">报名客户:</p>
            <p class="data">{{showDetail.enrollmentCount}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">售券客户:</p>
            <p class="data">{{showDetail.cardCount}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">秒杀客户:</p>
            <p class="data">{{showDetail.seckillUserCount}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">逛店客户:</p>
            <p class="data">{{showDetail.goShopCount}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">过程转单客户:</p>
            <p class="data">{{showDetail.processSelfOrderCountOrder}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">进入直播间客户:</p>
            <p class="data">{{showDetail.liveViewCount}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">直播下订客户:</p>
            <p class="data">{{showDetail.liveOrderCusCount}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">活动签到客户:</p>
            <p class="data">{{showDetail.actSignCount}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">直播转单客户:</p>
            <p class="data">{{showDetail.liveSelfOrderChangeCount}}</p>
          </div>
        </div>
      </el-card>
      <p class="view_title">订单信息</p>
      <el-card class="box-card">
        <div class="basic_info_wrap">
          <div class="basic_info_item">
            <p class="name">秒杀订单:</p>
            <p class="data">{{showDetail.seckillUserCountOrder}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">直播自签订单:</p>
            <p class="data">{{showDetail.liveSelfOrderCountOrder}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">直播贡献订单:</p>
            <p class="data">{{showDetail.liveOtherOrderCountOrder}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">直播自签转单:</p>
            <p class="data">{{showDetail.liveSelfOrderChangeCountOrder}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">直播贡献转单:</p>
            <p class="data">{{showDetail.liveOtherOrderChangeCountOrder}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">过程自签单:</p>
            <p class="data">{{showDetail.processSelfOrderCountOrder}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">过程贡献单:</p>
            <p class="data">{{showDetail.processDevoteOrderCountOrder}}</p>
          </div>
        </div>
      </el-card>
    </el-dialog>

    <!-- 编辑导购员 -->
    <el-drawer
      title="信息编辑"
      :visible.sync="editGuideDrawer"
      direction="rtl"
      size="500px"
      custom-class="editGuide_drawer"
    >
      <el-form :model="editGuideForm" :rules="editGuideRules" label-width="100px">
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model="editGuideForm.name"
            placeholder="请输入商品名称"
            maxlength="20"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="mobile">
          <el-input
            v-model="editGuideForm.mobile"
            placeholder="请输入手机号码"
            maxlength="20"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="品牌商" :required="editGuideForm.type!='act'">
          <el-select v-model="editGuideForm.brandId" placeholder="请选择" @change="getShop">
            <el-option label="暂无" :value="0" disabled></el-option>
            <el-option v-for="item in BrandList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="门店" :required="editGuideForm.type!='act'">
          <el-select v-model="editGuideForm.shopId" placeholder="请选择">
            <el-option label="暂无" :value="0" disabled></el-option>
            <el-option v-for="item in ShopList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色类型" prop="type">
          <el-select v-model="editGuideForm.type" placeholder="请选择">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
            <!-- :disabled="item.value=='act'" -->
          </el-select>
        </el-form-item>
        <el-form-item label="注册时间" prop="createTime">{{editGuideForm.createTime}}</el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitInfo">保 存</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
import $ from "jquery";
// import { isPhone } from "@/utils/utils.js";
import { getSession, setSession } from "@/utils/utils.js";
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
import GuideAjax from "@/utils/https/modules/Guide.request.js";

export default {
  name: "ShoppingGuide", // 导购员设置-导购员名单
  components: {},
  props: {
    activityID: String,
  },
  data() {
    return {
      input: "",
      selectValue: "",
      pageIndex: 1, // 当前页码
      pageSize: 10, // 请求单前页码总条数
      secondsKillTotal: 0, // 总条数
      ViewWidth: "", // 窗体大小限制
      detailsGuideDrawer: false, // 导购员列表 - 查看详情
      editGuideDrawer: false, // 编辑导购员
      editGuideForm: {
        actId: this.activityID,
        brandId: 1,
        id: "",
        mobile: "",
        name: "",
        recharged: "",
        shopId: 1,
        type: "",
        userId: "",
      },
      editGuideRules: {
        name: [
          { required: true, message: "请输入导购员姓名", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
        ],
        // brand: [{ message: "请选择品牌商", trigger: "blur" }],
        // shop: [{ message: "请输入门店", trigger: "blur" }],
        type: [{ required: true, message: "请输入角色类型", trigger: "blur" }],
      },

      // 导购员列表正式数据
      QueryKpiUserList: [],
      AuditList: [],
      QueryKpiUserTotal: 0,
      AuditTotal: 0,
      typeOptions: [
        { value: "act", label: "活动负责人" },
        { value: "brand", label: "品牌负责人" },
        { value: "staff", label: "导购员" },
        // { value: "finance", label: "财务负责人" },
      ],
      rechargedOptions: [
        { value: true, label: "已充值" },
        { value: false, label: "未充值" },
      ],
      auditOptions: [
        { value: "under_review", label: "未审核" },
        // { value: "pass", label: "审核通过" },
        { value: "no_pass", label: "已拒绝" },
      ],
      QueryKpiUserInfo: {
        actId: 0,
        mobile: "",
        name: "",
        page: 1,
        recharged: "",
        size: 10,
        type: "",
        userId: "",
      },
      AuditInfo: {
        actId: 0,
        mobile: "",
        name: "",
        page: 1,
        status: "",
        size: 10,
        timeRange: [],
        startTime: "",
        endTime: "",
      },
      showDetail: {
        actId: 0,
      },
      pickerOptionsStart: {
        disabledDate(time) {
          return (
            time.getTime() < new Date(new Date().toLocaleDateString()).getTime()
          );
        },
      },
      multipleSelection: [],
      BrandList: [],
      ShopList: [],
      activeName: "1",
      isSuperAdmin:true,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 切换直播订单类型
    handleClick() {
      if (this.activeName == "1") {
        this.QueryKpiUserInfo = {
          actId: 0,
          mobile: "",
          name: "",
          page: 1,
          recharged: "",
          size: 10,
          type: "",
          userId: "",
        };
        this.getQueryKpiUserList();
      } else if (this.activeName == "2") {
        this.AuditInfo = {
          actId: 0,
          mobile: "",
          name: "",
          page: 1,
          status: "",
          size: 10,
          timeRange: [],
          startTime: "",
          endTime: "",
        };
        this.getAuditList();
      }
    },
    // 多选方法
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 表格 页码请求（导购员列表）
    handleCurrentChange(val) {
      this.QueryKpiUserInfo.page = val;
      this.getQueryKpiUserList();
    },
    searchPage() {
      this.QueryKpiUserInfo.page = 1;
      this.getQueryKpiUserList();
    },
    // 表格 页码请求(审核列表)
    handleCurrentChange2(val) {
      this.AuditInfo.page = val;
      this.getAuditList();
    },
    searchPage2() {
      this.AuditInfo.page = 1;
      this.getAuditList();
    },

    // 获取导购员列表
    async getQueryKpiUserList() {
      try {
        this.QueryKpiUserInfo.actId = this.activityID;
        const {
          data: { list, total },
        } = await GuideAjax.getQueryKpiUserList(this.QueryKpiUserInfo);
        this.QueryKpiUserList = list;
        this.QueryKpiUserTotal = total;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 获取审核列表
    async getAuditList() {
      try {
        this.AuditInfo.actId = this.activityID;
        let act = this.AuditInfo;
        if (act.timeRange && act.timeRange.length > 0) {
          act.startTime = act.timeRange[0];
          act.endTime = act.timeRange[1];
          // delete act.timeRange;
        }
        const {
          data: { list, total },
        } = await GuideAjax.getAuditList(act);
        this.AuditList = list;
        this.AuditTotal = total;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 获取导购员详情
    async getQueryKpiUserDetail(Id) {
      try {
        const data = await GuideAjax.getQueryKpiUserDetail(Id);
        this.showDetail = data.data;
        this.detailsGuideDrawer = true;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 获取导购员编辑详情
    async getEditKpiUserDetail(Id) {
      try {
        const data = await GuideAjax.getEditKpiUserDetail(Id);
        this.editGuideForm = data.data;
        this.editBrandId = this.editGuideForm.brandId;
        await this.getActivityBrandShopList();
        this.editGuideDrawer = true;
      } catch (error) {
        this.$message.error(error);
      }
    },
    searchQueryKpiUserList() {
      this.getQueryKpiUserList();
    },
    // 导出导购员表格
    async exportData() {
      try {
        const data = await GuideAjax.getExportQueryKpiUser(
          this.QueryKpiUserInfo
        );
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "导购员列表.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 进入导购员详情
    toDetail(row) {
      this.getQueryKpiUserDetail(row.id);
    },
    // 进入编辑导购员详情
    toeditDetail(row) {
      this.getEditKpiUserDetail(row.id);
    },
    // 删除导购员
    async delConfirm(row) {
      try {
        const data = await GuideAjax.delKpiUser(row.id);
        this.$message.success("删除成功");
        await this.getQueryKpiUserList();
      } catch (error) {
        this.$message.error(error);
      }
    },
    isPhone(phone) {
      var bValidate = RegExp(
        /^(0|86|17951)?(13[0-9]|15[012356789]|18[0-9]|17[0-9]|19[0-9]|14[57])[0-9]{8}$/
      ).test(phone);
      if (bValidate) {
        return true;
      } else {
        return false;
      }
    },
    // 确认编辑导购员信息
    async submitInfo() {
      if (!this.isPhone(this.editGuideForm.mobile)) {
        this.$message.warning("请输入正确的手机格式");
        return;
      }
      if (this.editGuideForm.type != "act" && !this.editGuideForm.brandId) {
        this.$message.warning("请选择该用户归属品牌");
        return;
      }
      if (this.editGuideForm.type == "staff" && !this.editGuideForm.shopId) {
        this.$message.warning("请选择该用户归属门店");
        return;
      }

      let act = JSON.parse(JSON.stringify(this.editGuideForm));
      await GuideAjax.editKpiUser(act);
      this.$message.success("保存成功");
      await this.getQueryKpiUserList();
      this.editGuideDrawer = false;
    },
    ConfirmSetAuditStatus(Id, Status, Type) {
      var self = this;
      var title = "";
      var name = "";
      if (Type == "act") {
        name = "活动负责人";
      } else if (Type == "brand") {
        name = "品牌负责人";
      } else if (Type == "staff") {
        name = "导购员";
      }
      if (Status == "pass") {
        title = `同意该用户成为${name}吗？`;
      } else {
        title = `拒绝该用户成为${name}吗？`;
      }
      this.$confirm(title, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.SetAuditStatus(Id, Status);
        })
        .catch(() => {});
    },
    // 单个审核操作
    async SetAuditStatus(Id, Status) {
      try {
        var ids = [];
        ids.push(Id);
        const data = await GuideAjax.getBathAudit({
          ids: ids,
          status: Status,
        });
        this.$message.success("操作成功！");
        await this.getAuditList();
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 批量操作
    async SetIsAllow(type) {
      var self = this;
      var ids = [];
      // 过滤招聘信息
      for (var i = 0; i < self.multipleSelection.length; i++) {
        var item = self.multipleSelection[i];
        if (item.status != "under_review") {
          self.$message.warning("请选择未审核的信息"); // 弹出提示
          return;
        }
        if (item) {
          ids.push(item.id);
        }
      }
      if (ids.length < 1) {
        self.$message.warning("请先选择需要批量操作的信息"); // 弹出提示
        return;
      }
      const data = await GuideAjax.getBathAudit({
        ids: ids,
        status: type,
      });
      this.$message.success("操作成功！");
      await this.getAuditList();
    },
    // 查询品牌商列表
    async getActivityBrandList() {
      try {
        const data = await ActivityAjax.getActivityBrandList({
          actId: this.activityID,
          catId: "",
          name: "",
          page: 1,
          size: 1000,
        });
        if (data.data) {
          this.BrandList = data.data.list;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    getShop(val) {
      console.log(val);
      this.editBrandId = val;
      this.getActivityBrandShopList();
      this.editGuideForm.shopId = "";
    },
    // 查询品牌商列表
    async getActivityBrandShopList() {
      try {
        const data = await ActivityAjax.getActivityBrandShopList({
          actId: this.activityID,
          brandId: this.editBrandId,
          name: "",
          page: 1,
          size: 1000,
        });
        if (data.data) {
          this.ShopList = data.data.list;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
  created() {
    this.isSuperAdmin = getSession("issuperadmin")===false?false:true;
    // 初始加载数据
    this.getQueryKpiUserList();
    // this.getAuditList();
    this.getActivityBrandList();
  },
  mounted() {
    this.$nextTick(() => {
      $(".content").width() > 1280
        ? (this.ViewWidth = "1280".toString())
        : (this.ViewWidth = "80%");
    });
  },
};
</script>
<style lang="scss">
.ShoppingGuide_wrapper {
  .el-tabs__content {
    .select_wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 20px;
    }
    .secondsKill_table {
      margin-top: 20px;
      .el-table__body {
        .cell {
          span {
            margin-right: 10px;
            cursor: pointer;
          }
        }
      }
    }
    .tab-page {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
    }
  }
  // 导购员列表 - 查看详情
  .detailsGuide_drawer {
    .view_title {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 30px;
      &:before {
        display: inline-block;
        width: 3px;
        height: 23px;
        vertical-align: sub;
        background-color: #419eff;
        line-height: 2;
        margin-right: 20px;
        content: "";
      }
    }
    .el-card {
      margin-bottom: 30px;
      .basic_info_wrap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .basic_info_item {
          display: flex;
          flex-direction: row;
          height: 40px;
          line-height: 40px;
          color: #409eff;
          border: 1px solid #d9ecff;
          background-color: #ecf5ff;
          margin: 10px;
          padding: 0 20px;
          cursor: pointer;
          border-radius: 4px;
          &:hover {
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          }
          .name {
            padding-right: 20px;
          }
        }
      }
    }
  }
  // 编辑导购员
  .editGuide_drawer {
    .el-drawer__body {
      padding: 0 20px;
      overflow-y: auto;
    }
  }
}
</style>
